import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';
import * as $ from 'jquery';

@Component({
	selector: 'simplywhite-layout',
	templateUrl: './simplywhite.component.html',
	styleUrls: ['./simplywhite.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SimplyWhiteLayout extends RootLayout implements OnInit {

	notificationSize: number = 0;
	errors: boolean = false;
	showNotifications: boolean = false;
	menuLinks = [
		{
			label: "Reports",
			iconType: "fal",
			iconName: "far fa-coins",
			toggle: "close",
			roles: [1],
			mToggle: "close",
			submenu: [
				{
					label: "Sales Report",
					routerLink: "/reports/sales",
					iconType: "letter",
					iconName: "t",
					roles: [1],
				},
				// {
				// 	label: "Stock Report",
				// 	routerLink: "/sales/client",
				// 	iconType: "letter",
				// 	iconName: "c",
				// 	roles: [1],
				// },
			]
		},
		{
			label: "New Sales Order",
			routerLink: "/sales/order/new",
			roles: [1, 2, 3],
			iconType: "fal",
			iconName: "fa-cash-register",
		},
		{
			label: "Sales",
			iconType: "fal",
			iconName: "far fa-coins",
			toggle: "close",
			roles: [1, 2, 3],
			mToggle: "close",
			submenu: [
				{
					label: "Sales Order",
					routerLink: "/sales/order",
					iconType: "letter",
					iconName: "t",
					roles: [1, 2],
				},
				{
					label: "Client Account",
					routerLink: "/sales/client",
					iconType: "letter",
					iconName: "c",
					roles: [1, 2],
				},
			]
		},
		// {
		// 	label: "Purchase Orders",
		// 	routerLink: "/purchase-order",
		// 	roles: [1, 2, 3],
		// 	iconType: "fal",
		// 	iconName: "far fa-copy",
		// },
		{
			label: "Stock",
			routerLink: "/stock",
			roles: [1, 2],
			iconType: "fal",
			iconName: "far fa-box-open",
		},
	
		// {
		// 	label: "Clients",
		// 	routerLink: "/client",
		// 	roles: [1, 2],
		// 	iconType: "fal",
		// 	iconName: "far fa-user",
		// },
		{
			label: "Settings",
			iconType: "fal",
			iconName: "far fa-copy",
			toggle: "close",
			mToggle: "close",
			roles: [1],
			submenu: [
				{
					label: "Users",
					routerLink: "/user",
					roles: [1],
					iconType: "fal",
					iconName: "far fa-users",
				},
				{
					label: "Stock Category",
					routerLink: "/stockcategory",
					roles: [1],
					iconType: "fal",
					iconName: "far fa-truck-loading",
				},
				{
					label: "Stock Location",
					routerLink: "/location",
					roles: [1],
					iconType: "fal",
					iconName: "far fa-location",
				}
			]
		},
	];
	ngOnInit() {
		this.changeLayout("menu-pin");
		//Will sidebar close on screens below 1024
		this.autoHideMenuPin();
		// document.documentElement.style.setProperty('--primary-color', '#546E87');
		// document.documentElement.style.setProperty('--secondary-color', '#7DA1B5');
		this.filterMenu(this.authService);
		// this.getNotificationSize();
	}

	getLogo() {
		return this.authService.getCompanyLogo();
	}

	isAuthenticated() {
		return this.authService.authenticated;
	}

	signout() {
		this.authService.signout();
	}

	isSystemAdmin(): boolean {
		if (this.authService.getUserRole() == 1) {
			return true;
		}
		return false;
	}

	getNotificationSize() {
		let _this = this;
		this.notificationService.getCount().subscribe(res => {
			_this.notificationSize = res['data']['count'];
		},
			err => {
				console.log(err);
			})
	}

	getFullName() {
		if (!this.authService.user) {
			return "";
		}
		console.log("Logged in as" + this.authService.user.name);
		return this.authService.user.name;
	}

	filterMenu(authService) {
		let role = authService.getUserRole();
		for (let parent = 0; parent < this.menuLinks.length; parent++) {
			if (!this.menuLinks[parent].roles.includes(role)) {
				this.menuLinks[parent] = null;
			}
		}
		this.menuLinks = this.menuLinks.filter(Boolean);
	}

}
